<template>
  <div class="header">
    <div>
      <el-dropdown @command="handleClick">
        <div class="el-dropdown-link">
          <img src="http://cdn.topartsintermational.com/menu.png" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(item, index) in menu"
            :key="index"
            :command="item.name"
            >{{ item.name }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="text">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    title: String,
  },
  data () {
    return {
      isCollapse: true,
      menu: [
        {
          name: this.$t('message.link.preface'),
          link: '/preface',
        },
        {
          name: this.$t('message.link.history'),
          link: '/history',
        },
        {
          name: this.$t('message.link.warriors'),
          link: '/warriors',
        },
        {
          name: this.$t('message.link.ancient'),
          link: '/ancient',
        },
        {
          name: this.$t('message.link.culture'),
          link: '/culture',
        },
        {
          name: this.$t('message.link.visit'),
          link: '/visit',
        },
        {
          name: this.$t('message.link.creation'),
          link: '/creation',
        },
        {
          name: this.$t('message.link.game'),
          link: '/game',
        },
      ],
    }
  },
  methods: {
    handleClick (command) {
      const index = this.menu.findIndex(item => item.name === command)
      const url = this.menu[index].link
      if (this.$route.path === url) return
      this.$router.push(url)
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  height: 50px;
  background: #012545;
  text-align: left;
  padding: 0 15px;
}
.el-dropdown {
  height: 100%;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  height: 100%;
  display: flex;
  align-items: center;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.text {
  color: #fff;
  font-size: 22px;
  margin-left: 15px;
  line-height: 50px;
}
</style>