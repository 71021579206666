<!--
 * @Author: 月魂
 * @Date: 2020-12-01 16:46:45
 * @LastEditTime: 2022-01-31 12:43:32
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \bingmayong-ukraine-pc\src\components\footer.vue
-->
<template>
  <div class="footer">
    <div class="cont">
      <div class="icon">
        <a
          href="https://twitter.com/visitshaanxi"
          target="_blank"
        >
          <img src="../assets/t.png" width="30px" height="30px" />
        </a>
        <a
          href="https://www.facebook.com/visitshaanxi"
          target="_blank"
        >
          <img src="../assets/f.png" width="30px" height="30px" />
        </a>
      </div>
      <div class="cont-menu">
        <!-- <p>Host:China Cultural Center（Sydney）</p>
        <p>Shaanxi Provincial Department of Cultureand Tourism</p>
        <p>
          Organizers：Shaanxi Wenyi Internatioanl Culture Development co., Ltd
        </p>
        <p>Special thanks:Emperor Qinshihuang's Mausoleum Site Museum</p> -->
        <el-row>
          <el-col :span="5">{{ $t("message.footer.left.title") }}</el-col>
          <el-col :span="19">
            <p>{{ $t("message.footer.left.item1") }}</p>
            <p>{{ $t("message.footer.left.item2") }}</p>
          </el-col>
        </el-row>
      </div>
      <div class="cont-menu">
        <!-- <el-row>
          <el-col :span="4">{{ $t("message.footer.right.title") }}</el-col>
          <el-col :span="20"><p>{{ $t("message.footer.right.item") }}</p></el-col>
        </el-row> -->
        <el-row>
          <el-col :span="7">{{ $t("message.footer.thanks.title") }}</el-col>
          <el-col :span="17"><p>{{ $t("message.footer.thanks.item") }}</p></el-col>
        </el-row>
      </div>
      <!-- <div class="cont-menu all-line">
        <div class="title">{{ $t("message.footer.thanks.title") }}</div>
        <div class="des">{{ $t("message.footer.thanks.item") }}</div>
      </div> -->
    </div>
    <div class="qrcode">
      <img src="../assets/qrcode1.jpg" alt="" class="img">
      <img src="../assets/qrcode2.jpg" alt="" class="img">
    </div>
    <div style="text-align: center; margin-top: 10px;">
      <a
        href="https://beian.miit.gov.cn"
        rel="nofollow noopener noreferrer"
        target="_blank"
        style="color: #fff; text-decoration: none"
      >
        陕ICP备20012594号-2
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.footer {
  width: 100%;
  background: #012545;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
}
.cont {
  width: 1200px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}
.cont-menu {
  width: 46%;
  color: #fff;
  padding-left: 16px;
}
.all-line {
  width: 100%;
  display: flex;
}
.title {
  margin-right: 30px;
}
.des {
  margin-right: 10px;
}
.cont-menu p {
  margin: 0;
  padding: 0;
  line-height: 24px;
  font-weight: 500;
}

.qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}
.img {
  display: block;
  width: 80px;
  margin-right: 10px;
}
</style>